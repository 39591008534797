import React, { useEffect, useState } from "react";
import { Toaster, toast } from "react-hot-toast";
import EyeIcon from "../icons/EyeIcon";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import LoadingSpinner from "../shared/LoadingSpinner";

function Register() {
  const navigate = useNavigate();
  const [serchParams] = useSearchParams();
  const email = serchParams.get("email");
  const referralEmail =  serchParams.get("referralEmail");
  const [authEmail, setAuthEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);
  // const [countryCode,setCountryCode] = useState("")
  

  // const [countries, setCountries] = useState([]);
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    password: "",
    confirmPassword: "",
    country: "",
    phoneNumber: "",
  });

  useEffect(() => {
    const getAuthEmail = JSON.parse(localStorage.getItem("authCredentials"));
    setAuthEmail(getAuthEmail?.email);
  }, []);

  // useEffect for the country
  // useEffect(() => {
  //   fetchCountries();
  //   getCountryCode();
   
  //   async function fetchCountries(){
  //     try {
  //       const res = await fetch(`https://restcountries.com/v3.1/all`);
  //       const json = await res.json();
  //       setCountries(json);
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   };

  //     fetch a single country by name
  //   async function getCountryCode(){
  //   const res  = await fetch(`https://restcountries.com/v3.1/name/${user.country}`);
  //   const resObj = await res.json();
  //   console.log(resObj[0])
  //   const {idd:{root, suffixes }} = resObj[0];
  //   const code = root + suffixes.join("");
  //   setCountryCode(code)
  // }
  // }, [user.country]);

  // For Single Country Fetch




  // Handle Password and confirm passswords
  const handlePassword = () => {
    setPassword((prev) => !prev);
  };
  const handleConfirmPassword = () => {
    setConfirmPassword((prev) => !prev);
  };

  // Handle Change
  const handleChange = (e) => {
    setUser((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  // Handle Submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    if(!user.firstName || !user.lastName || !user.password || !user.confirmPassword || !user.country || !user.phoneNumber){
      return toast.error("Please fill the necessary details !")
    }
    setLoading(true);
    const data = {
      firstName: user.firstName,
      lastName: user.lastName,
      password: user.password,
      confirmPassword: user.confirmPassword,
      country: user.country,
      phoneNumber: user.phoneNumber,
    };

    const url = `${process.env.REACT_APP_REGISTER_URL}?email=${email}&${referralEmail ? `referralEmail=${referralEmail}` : ""}`;
    // console.log(url);
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };
    try {
      const response = await fetch(url, params);
      const resObj = await response.json();

      // if the response was false
      if (!response.ok) {
        throw new Error(resObj.message);
        // setLoading(false);
      }
      // console.log(resObj?.data);
      toast.success(resObj?.message);
      setLoading(false);

      // Login the user after 3 seconds
      setTimeout(() => {
        navigate("/login");
      }, 3000);
    } catch (err) {
      toast.error(err.message);
      setLoading(false);
    }
  };
  
  // Print countries
  // console.log(countries.map(country => country))
  return (
    <section className="auth-section">
      <div className="form-container">
        {/* React hot Toast */}
        <Toaster position="top-center" reverseOrder={false}></Toaster>

        <div className="form-header">
          <Link to="/" className="img-container">
            <img src={`/assets/tetfi.png`} alt="logo" />
          </Link>
        </div>
        <div className="form-body">
          <form onSubmit={handleSubmit}>
            <div className="double">
              <div className="form-field">
                <label htmlFor="firstName">first name</label>
                <input
                  type="text"
                  name="firstName"
                  id="firstName"
                  placeholder="Jeo"
                  value={user.firstName}
                  onChange={handleChange}
                />
              </div>
              <div className="form-field">
                <label htmlFor="lastName">last name</label>
                <input
                  type="text"
                  name="lastName"
                  id="lastName"
                  placeholder="Dick"
                  value={user.lastName}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-field">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                name="email"
                id="email"
                value={authEmail}
                placeholder="jeodick@gmail.com"
                readOnly
              />
            </div>
            <div className="double">
              <div className="form-field">
                <label htmlFor="password">password</label>
                <input
                  type={password ? "text" : "password"}
                  name="password"
                  id="password"
                  placeholder="*******"
                  value={user.password}
                  onChange={handleChange}
                />
                <span
                  className={`eye-icon-container ${password ? "active" : ""}`}
                  onClick={handlePassword}
                >
                  <EyeIcon />
                </span>
              </div>
              <div className="form-field">
                <label htmlFor="confirmPassword">confrim password</label>
                <input
                  type={confirmPassword ? "text" : "password"}
                  name="confirmPassword"
                  id="confirmPassword"
                  placeholder="*******"
                  value={user.confirmPassword}
                  onChange={handleChange}
                />
                <span
                  className={`eye-icon-container ${confirmPassword ? "active" : ""}`}
                  onClick={handleConfirmPassword}
                >
                  <EyeIcon />
                </span>
              </div>
            </div>
            <div className="form-field">
              <label htmlFor="country">country</label>
              {/* <div className="select-field">
                <select
                  name="country"
                  id="country"
                  value={user.country}
                  onChange={handleChange}
                >
                  {countries.slice(0, 1).map((country) => (
                    <option key={country.name.common}>
                      {country.name.common}
                    </option>
                  ))}

                  {countries.slice(1).map((country) => (
                    <option key={country.name.common}>
                      {country.name.common}
                    </option>
                  ))}
                </select>
              </div> */}
              <input 
              type="text" 
              name="country"
              id="country"
              value={user.country}
              onChange={handleChange}
              placeholder="Enter your country"
              />
            </div>
            <div className="form-field">
              <label htmlFor="phoneNumber">phone number</label>
              <div className="flex items-center">
              {/* <span className="dail-code p-4" id="dail-code">{countryCode}</span> */}
              <input 
              type="number"  
              id="phoneNumber" 
              name="phoneNumber" value={user.phoneNumber}
              placeholder="Enter your contact number"
               onChange={handleChange} />
              </div>
            </div>

            <div className="form-field">
              <button disabled={loading}>
                <span>{loading ? <LoadingSpinner /> : "register"}</span>
              </button>
            </div>
            {/* OR */}

            <div className="form-field flex items-center justify-center">
              <span>Already have an account ?</span>
              <Link className="text-[#2B6CB0]" to="/login">
                login
              </Link>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}

export default Register;
